import { Button, Heading, Text } from '@/components/ui'
import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { HiOutlineShieldExclamation } from 'react-icons/hi2'
import { useCallback } from 'react'

const Error404: NextPage = () => {
  const router = useRouter()

  const handleGoBack = useCallback(() => {
    router.back()
  }, [router])

  return (
    <>
      <Head>
        <title>Page not found - Playlolly</title>
      </Head>

      <div className="flex items-center min-h-screen mx-auto">
        <div className="flex flex-col items-center mx-auto text-center">
          <div className="rounded-full p-7 bg-tesla-red">
            <HiOutlineShieldExclamation className="text-white text-4xl" />
          </div>
          <Heading visualLevel="2" className="pt-5">
            Page not found
          </Heading>
          <Text className="pt-5">
            The page you are looking for doesn&apos;t exist. Here are some
            helpful links:
          </Text>

          <div className="flex items-center justify-center pt-10 gap-x-2.5">
            <Button onClick={handleGoBack}>
              ← Go back
            </Button>

            <Button color="corporate" href="/">
              Take me home
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error404
